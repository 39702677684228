

function BgOverlay()
{
    return (
        <div className="absolute inset-0">
            <img
                src={'/assets/background.jpg'}
                className="w-screen h-screen object-cover"
            />
            
            <div className={`
            absolute inset-0
        h-screen w-screen bg-black opacity-50
        `}
            />
        </div>
    );
}

export default BgOverlay;
