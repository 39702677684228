import { useEffect, useState, useRef, useContext } from 'react';
import BgOverlay from './components/BgOverlay/BgOverlay';
import Home from './components/Home/Home';
import MyNavbar from './components/MyNavbar/MyNavbar';
import { ClimbingBoxLoader } from 'react-spinners';

import { GlobalVarsContext } from './context/context';
import About from './components/About/About';
import Founders from './components/Founders/Founders';
import Projects from './components/Projects/Projects';
import FAQs from './components/FAQs/FAQs';
import Contact from './components/Contact/Contact';

const loadInterval = 3;

function App()
{
    const [isLoading, setIsLoading] = useState(true); // default true
    const { setPxValue } = useContext(GlobalVarsContext);

    const contentRef = useRef(undefined);

    useEffect(() =>
    {

        window.addEventListener(
            'resize',
            () =>
            {
                const computedStyle = window.getComputedStyle(contentRef.current);
                console.log(computedStyle['margin-left'].split('px')[0]);
                const xgap = parseFloat(computedStyle['margin-left'].split('px')[0]) + parseFloat(computedStyle['padding-left'].split('px')[0]);
                setPxValue(xgap);
            },
        );

        setTimeout(() =>
        {
            setIsLoading(false);
        }, 1000 * loadInterval);
    }, []);

    return (
        <>
            {
                isLoading && (
                    <div className='fixed bg-darkbg h-screen w-screen z-10'>
                        <ClimbingBoxLoader
                            cssOverride={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                            }}
                            color='#FFFFFF'
                            loading={isLoading}
                            className='animate-fade-out'
                        />
                    </div>
                )
            }
            <div className={` relative
            h-screen w-screen flex flex-col animate-fade-in delay-300
            overflow-x-hidden overflow-y-auto scroll-smooth items-center
            `}
            >
                {/* [Begin] Content */}
                <MyNavbar />
                <BgOverlay />
                <div
                    ref={contentRef}
                    className={'absolute top-0 left-0 right-0 h-max flex flex-col overflow-hidden items-center'}
                >
                    <Home className={'px-10'} />
                    <About className={'max-w-screen-lg px-10'} />
                    <Founders className={'max-w-screen-lg px-10'} />
                    <Projects className={'max-w-screen-lg px-10'} />
                    <FAQs className={'max-w-screen-lg px-10'} />
                    <Contact className={'max-w-screen-lg px-10'} />
                </div>
                {/* [End] Content */}

            </div>
        </>
    );
}

export default App;
