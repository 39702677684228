import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import LanguagesProvider from './context/Languages/LanguagesProvider';
import RouteProvider from './context/Routes/RouteProvider';
import GlobalVarsProvider from './context/GlobalVars/GlobalVarsProvider';
import { ThemeProvider } from '@material-tailwind/react';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ThemeProvider>
        <LanguagesProvider>
            <RouteProvider>
                <GlobalVarsProvider>
                    <App />
                </GlobalVarsProvider>
            </RouteProvider>
        </LanguagesProvider>,
    </ThemeProvider>,
);
