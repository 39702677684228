import {
    Card,
    CardHeader,
    CardBody,
    Typography,
} from '@material-tailwind/react';


function About({ className })
{
    return (
        <div className={`max-w-screen-lg flex flex-col h-max py-10 items-center justify-between gap-10 ${className}`}>
            <Card className="w-full md:flex-row md:items-center overflow-hidden">
                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 rounded-none md:w-1/2"
                >
                    <img
                        src="assets/office/office3.jpg"
                        alt="ui/ux review check"
                    />
                </CardHeader>
                <CardBody className='md:w-1/2'>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                    >
    Welcome to the condo real
                    </Typography>
                    <Typography
                        variant="lead"
                        color="gray"
                        className="mt-3 font-normal"
                    >
    Because it&apos;s about motivating the doers. Because I&apos;m here to
    follow my dreams and inspire others.
                    </Typography>
                </CardBody>
            </Card>
            <Card className="w-full md:flex-row-reverse md:items-center overflow-hidden">
                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 rounded-none md:w-1/2"
                >
                    <img
                        src='assets/office/office1.jpg'
                        alt="ui/ux review check"
                    />
                </CardHeader>
                <CardBody className='md:w-1/2'>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                    >
    Our journey: building  dreams in real estate
                    </Typography>
                    <Typography
                        variant="lead"
                        color="gray"
                        className="mt-3 font-normal"
                    >
    Because it&apos;s about motivating the doers.
                    </Typography>
                </CardBody>
            </Card>
            <Card className="w-full overflow-hidden md:flex-row md:items-center">
                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 rounded-none md:w-1/2"
                >
                    <img
                        src="assets/office/office2.jpg"
                        alt="ui/ux review check"
                    />
                </CardHeader>
                <CardBody className='md:w-1/2'>
                    <Typography
                        variant="h4"
                        color="blue-gray"
                    >
    About us
                    </Typography>
                    <Typography
                        variant="lead"
                        color="gray"
                        className="mt-3 font-normal"
                    >
    Because it&apos;s about motivating the doers. Because I&apos;m here to
    follow my dreams and inspire others.
                    </Typography>
                </CardBody>
            </Card>
        </div>
    );
}

export default About;
