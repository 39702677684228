import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
    Typography,
    Button,
} from '@material-tailwind/react';
const MSG = ['Marketing Solution Packages', 'Benifit Plus', 'Success Guaranteed'];


function Home({ className })
{

    return (
        <div
            className={`
            flex flex-col justify-center items-center h-screen text-white ${className}
            `}
        >
            <Typography
                variant='h1'
                className={`
                capitalize py-8 text-center font-extrabold text-6xl tracking-tight md:text-7xl 
                drop-shadow-xl shadow-red-500
                bg-gradient-to-r from-red-900 via-yellow-600 to-yellow-800 bg-clip-text text-transparent 
                `}
            >Chất lượng hàng đầu
            </Typography>
            {/* <h1
                className={`bg-gradient-to-r from-green-300 via-blue-500 to-purple-600
                bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl drop-shadow-lg
                pb-2
                animate-pulse`}
            >
        Understand User Flow.

                <span className="sm:block"> Increase Conversion. </span>
            </h1> */}
            <Typography
                variant='small'
                className='pb-4 text-center'
            >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Typography>

            <div className='grid grid-rows-2 grid-cols-2 py-8 gap-8 md:gap-x-40'>
                <div className='flex flex-col items-center justify-center'>
                    <Typography
                        variant='h2'
                        className='font-bold'
                    >100+
                    </Typography>
                    <Typography variant='small'>Projects completed</Typography>
                </div>
                <div className='flex flex-col items-center justify-center'>
                    <Typography
                        variant='h2'
                        className='font-bold'
                    >99%
                    </Typography>
                    <Typography variant='small'>Satisfied customers</Typography>
                </div>
                <div className='flex flex-col items-center justify-center'>
                    <Typography
                        variant='h2'
                        className='font-bold'
                    >25+
                    </Typography>
                    <Typography variant='small'>{'Year\'s experience'}</Typography>
                </div>
                <div className='flex flex-col items-center justify-center'>
                    <Typography
                        variant='h2'
                        className='font-bold'
                    >10M
                    </Typography>
                    <Typography variant='small'>Turn over in 2022</Typography>
                </div>
            </div>
            <Button
                variant='outlined'
                size="lg"
                color='white'
                className='pb-4'
            >Xem thêm
            </Button>
        </div>
    );
}

export default Home;
