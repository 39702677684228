import { useState, useEffect } from 'react';

import {
    Typography,
    List,
    ListItem,
    Collapse,
    Card,
} from '@material-tailwind/react';

import { FcExpand, FcCollapse } from 'react-icons/fc';


function FAQs({ className })
{
    const [selected, setSelected] = useState(-1);
    const toggleOpen = (item) =>
    {
        setSelected((prev)=>
        {
            if (prev == item)
            {
                return -1;
            }
            return item;
        });
    };
    const itemIdxes = Array.from(Array(4).keys());

    return (
        <div className='relative h-max min-h-screen w-full flex flex-col items-center justify-center py-20'>
            {/* overlay */}
            <div className='absolute inset-0 bg-gradient-to-br from-red-900 via-yellow-600 to-yellow-800 opacity-70 -z-10' />
            {/* overlay */}
            <div className={`gap-10 flex flex-col items-center justify-center ${className}`}>
                <Typography
                    variant='h1'
                    color='white'
                >FAQs
                </Typography>
                <Typography
                    variant='small'
                    color='white'
                    className='text-center md:px-10 drop-shadow-lg'
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
                {/* list collapse */}
                <Card className='py-10 w-full gap-4'>
                    <List className='w-full'>
                        {
                            itemIdxes.map(item=>(
                                <ListItem
                                    key={item}
                                    selected={selected == item}
                                    ripple={false}
                                    className="py-1 pr-1 pl-4 flex flex-col items-start justify-center"
                                    onClick={()=>toggleOpen(item)}
                                >
                                    <div className='flex justify-between items-center w-full px-2'>
                                        <Typography
                                            variant='lead'
                                            className='font-bold'
                                        >
                                Item {item + 1}
                                        </Typography>
                                        {
                                            selected == item
                                                ? (
                                                        <FcCollapse
                                                            className='font-bold text-2xl !fill-red-500'
                                                        />
                                                    )
                                                : (
                                                        <FcExpand
                                                            className='font-bold text-2xl !fill-red-500'
                                                        />
                                                    )
                                        }

                                    </div>
                                    {
                                        selected == item && (
                                            <Collapse
                                                open={selected == item}
                                                className='px-2 md:px-10'
                                            >
                                                <Typography>
                                            Use our Tailwind CSS collapse for your website. You can use if for
                                            accordion, collapsible items and much more.
                                                </Typography>
                                            </Collapse>
                                        )
                                    }

                                </ListItem>
                            ))
                        }

                    </List>
                </Card>

            </div>
        </div>

    );
}

export default FAQs;
