import React from 'react';
import {
    Typography,
} from '@material-tailwind/react';
import { TiLocation, TiMail, TiPhone } from 'react-icons/ti';

function Contact({ className })
{
    return (
        <div className={`h-max min-h-screen w-full flex flex-col items-center justify-center py-20 gap-4 ${className}`}>
            <Typography
                variant='h1'
                color='blue-gray'
                className='text-center'
            >Công ty cổ phần tập đoàn PKV Group
            </Typography>
            <Typography
                variant='lead'
                color='blue-gray'
                className='text-center pt-4'
            >Liên hệ với chúng tôi
            </Typography>
            <div className='flex flex-col md:flex-row w-full justify-around'>
                <div className='flex flex-col w-full items-center justify-center p-8'>
                    <TiLocation className='text-4xl' />
                    <Typography
                        variant='lead'
                        color='blue-gray'
                    >Địa chỉ
                    </Typography>
                    <Typography
                        as='a'
                        href='https://maps.app.goo.gl/b5xNxxEjjGb5o3Jv8'
                        variant='small'
                        color='blue-gray-200'
                        className='text-center text-gray-600 hover:text-blue-gray-300 hover:cursor-pointer'
                    >Số 88 Lô TT2, Khu X5 đường Nguyễn Hoàng, Phường Mỹ Đình 2, Quận Nam Từ Liêm, Thành phố Hà Nội
                    </Typography>
                </div>
                <div className='flex flex-col w-full items-center justify-center p-8'>
                    <TiMail className='text-4xl' />
                    <Typography
                        variant='lead'
                        color='blue-gray'
                    >Email
                    </Typography>
                    <Typography
                        as='a'
                        href='mailto:phampkv868@gmail.com'
                        variant='small'
                        color='blue-gray-200'
                        className='text-center text-gray-600 hover:text-blue-gray-300 hover:cursor-pointer'
                    >phampkv868@gmail.com
                    </Typography>
                </div>
                <div className='flex flex-col w-full items-center justify-center p-8'>
                    <TiPhone className='text-4xl' />
                    <Typography
                        variant='lead'
                        color='blue-gray'
                    >Phone
                    </Typography>
                    <Typography
                        as='a'
                        href='tel:0913683296'
                        variant='small'
                        className='text-center text-gray-600 hover:text-blue-gray-300 hover:cursor-pointer'
                    >0913 683 296
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default Contact;
