import { useState, useEffect } from 'react';

import { LanguageContext } from '../context';

import langVi from './vi.json';
import langEn from './en.json';

// 0: vi
// 1: en
const langEnum = [langVi, langEn];

export default function LanguagesProvider({ children })
{
    const [langId, setLangId] = useState(1);
    const [langPack, setLangPack] = useState(langEnum[langId]);

    const handleSwapLang = () =>
    {
        setLangId(prev => 1 - prev);
    };

    useEffect(() =>
    {
        const pack = langEnum[langId];
        setLangPack(pack);
    }, [langId]);

    const translate = (keyStr) =>
    {
        return langId === 1 ? keyStr : langPack[keyStr];
    };

    const provider = {
        langId,
        translate,
        handleSwapLang,
    };

    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    );
}
