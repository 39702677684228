
import logo from '../../images/Logo_PKV GROUP-01.png';
import menu from '../../images/hamburger.svg';

import { useState, useEffect } from 'react';
import {
    Navbar,
    Collapse,
    Typography,
    Button,
    IconButton,
} from '@material-tailwind/react';

export default function MyNavbar({ className })
{
    const [openNav, setOpenNav] = useState(false);

    const handleOpenNav = ()=>
    {
        setOpenNav(!openNav);
    };

    useEffect(() =>
    {
        const resizeListenerHandler = ()=>
        {
            console.log('resize listening');
            if (window.innerWidth >= 960)
            {
                setOpenNav(false);
            }
        };

        window.addEventListener(
            'resize',
            resizeListenerHandler,
        );
        return ()=>
        {
            window.removeEventListener('resize', resizeListenerHandler);
        };
    }, []);


    const navList = (
        <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 text-blue-gray-900">

            <Typography
                as="li"
                variant="lead"
                color="blue-gray"
                className="p-1 font-bold"
            >
                <a
                    href="#"
                    className="flex items-center "
                >
                    Home
                </a>
            </Typography>
            <Typography
                as="li"
                variant="lead"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <a
                    href="#"
                    className="flex items-center "
                >
                    About
                </a>
            </Typography>
            <Typography
                as="li"
                variant="lead"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <a
                    href="#"
                    className="flex items-center "
                >
                    Projects
                </a>
            </Typography>
            <Typography
                as="li"
                variant="lead"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <a
                    href="#"
                    className="flex items-center "
                >
                    Contact
                </a>
            </Typography>


        </ul>
    );
 
    return (
        <Navbar
            className={`sticky top-0 z-10 h-max max-h-18 max-w-full rounded-none p-4 md:p-2 ${className}
                border-0
            `}
        >
            <div className="flex items-center justify-between text-blue-gray-900 max-w-screen-lg mx-auto">
                {/* [Begin] Logo left */}
                <Typography
                    as="a"
                    href="#"
                    className="mr-4 cursor-pointer font-medium"
                >
                    <img
                        className="w-20 aspect-auto object-cover object-center"
                        src={logo}
                        alt="nature image"
                    />
                </Typography>
                {/* [End] Logo left */}

                {/* [Begin] Menu center */}
                <div className="mr-4 hidden lg:block">{navList}</div>
                {/* [End] Menu center */}

                {/* [Begin] Functions right */}
                <div className="flex items-center gap-x-1">
                    <Button
                        variant="text"
                        size="md"
                        className="hidden lg:inline-block"
                    >
                        <span>Log In</span>
                    </Button>
                    <Button
                        variant="gradient"
                        size="md"
                        className="hidden lg:inline-block"
                    >
                        <span>Sign in</span>
                    </Button>
                </div>
                {/* [End] Functions right */}

                {/* [Begin] Hamburger menu mobile */}
                <IconButton
                    variant="text"
                    className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                    ripple={false}
                    onClick={handleOpenNav}
                >
                    {openNav
                        ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    className="h-6 w-6"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            )
                        : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                </IconButton>
                {/* [End] Hamburger menu mobile */}

            </div>

            {/* [Begin] Menu mobile */}
            <Collapse open={openNav}>
                {navList}
                <div className="flex items-center gap-x-1">
                    <Button
                        variant="text"
                        size="sm"
                        className=""
                        fullWidth
                    >
                        <span>Log In</span>
                    </Button>
                    <Button
                        variant="gradient"
                        size="sm"
                        className=""
                        fullWidth
                    >
                        <span>Sign in</span>
                    </Button>
                </div>
            </Collapse>
            {/* [End] Menu mobile */}

        </Navbar>
    );
}
