import { useState } from 'react';
import {
    Typography,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from '@material-tailwind/react';
import { TiPhoneOutline, TiMail } from 'react-icons/ti';

function Founders({ className })
{

    return (
        <div className={`relative h-max min-h-screen w-full flex flex-col items-center justify-center py-20
        `}
        >
            {/* overlay */}
            <div className='absolute inset-0 bg-gradient-to-br from-red-900 via-yellow-600 to-yellow-800 opacity-70 -z-10' />
            {/* overlay */}
            <div className={`gap-10 flex flex-col items-center justify-center px-10 ${className}`}>
                <Typography
                    variant='h1'
                    color='white'
                >Founders
                </Typography>
                <div className='flex flex-col md:flex-row gap-10'>
                    <Card className="w-full">
                        <CardHeader
                            floated={false}
                            className="md:w-80 h-full"
                        >
                            <img
                                src="assets/founders/CEO.jpg"
                                alt="profile-picture"
                                className='w-full aspect-auto object-cover object-center'
                            />
                        </CardHeader>
                        <CardBody className="text-center">
                            <Typography
                                variant="h4"
                                color="blue-gray"
                                className="pb-2"
                            >
                                Nguyễn Văn Phẩm
                            </Typography>
                            <Typography
                                color="blue-gray"
                                className="font-medium"
                                textGradient
                            >
                                CBO
                            </Typography>
                        </CardBody>
                        <CardFooter className="flex flex-col justify-center items-start pt-0">
                            <div className='flex gap-2 justify-center items-center'>
                                <TiPhoneOutline className='text-[2rem] aspect-square fill-blue-800' />
                                <Typography
                                    as="a"
                                    href='tel:0913683296'
                                    color='blue-gray'
                                    variant="small"
                                    textGradient
                                >{'0913 683 296'}
                                </Typography>
                            </div>
                            <div className='flex gap-2 justify-center items-center'>
                                <TiMail className='text-[2rem] aspect-square fill-blue-800' />
                                <Typography
                                    as="a"
                                    color='blue-gray'
                                    variant="small"
                                    href='mailto:phampkv868@gmail.com'
                                    textGradient
                                >phampkv868@gmail.com
                                </Typography>
                            </div>
                        </CardFooter>
                    </Card>
                    {/* <Card className="w-full">
                        <CardHeader
                            floated={false}
                            className="h-full"
                        >
                            <img
                                src="https://docs.material-tailwind.com/img/team-3.jpg"
                                alt="profile-picture"
                            />
                        </CardHeader>
                        <CardBody className="text-center">
                            <Typography
                                variant="h4"
                                color="blue-gray"
                                className="pb-2"
                            >
            Natalie Paisley
                            </Typography>
                            <Typography
                                color="blue-gray"
                                className="font-medium"
                                textGradient
                            >
            CEO / Co-Founder
                            </Typography>
                        </CardBody>
                        <CardFooter className="flex flex-col justify-center items-start">
                            <div className='flex gap-2 justify-center items-center'>
                                <TiPhoneOutline className='text-[2rem] aspect-square fill-blue-800' />
                                <Typography
                                    as="a"
                                    href="#twitter"
                                    color='blue-gray'
                                    variant="small"
                                    textGradient
                                >{'(123)-123423232'}
                                </Typography>
                            </div>
                            <div className='flex gap-2 justify-center items-center'>
                                <TiMail className='text-[2rem] aspect-square fill-blue-800' />
                                <Typography
                                    as="a"
                                    href="#twitter"
                                    color='blue-gray'
                                    variant="small"
                                    textGradient
                                >abc@gmail.com
                                </Typography>
                            </div>
                        </CardFooter>
                    </Card>
                    <Card className="w-full">
                        <CardHeader
                            floated={false}
                            className="h-full"
                        >
                            <img
                                src="https://docs.material-tailwind.com/img/team-3.jpg"
                                alt="profile-picture"
                            />
                        </CardHeader>
                        <CardBody className="text-center">
                            <Typography
                                variant="h4"
                                color="blue-gray"
                                className="pb-2"
                            >
            Natalie Paisley
                            </Typography>
                            <Typography
                                color="blue-gray"
                                className="font-medium"
                                textGradient
                            >
            CEO / Co-Founder
                            </Typography>
                        </CardBody>
                        <CardFooter className="flex flex-col justify-center items-start">
                            <div className='flex gap-2 justify-center items-center'>
                                <TiPhoneOutline className='text-[2rem] aspect-square fill-blue-800' />
                                <Typography
                                    as="a"
                                    href="#twitter"
                                    color='blue-gray'
                                    variant="small"
                                    textGradient
                                >{'(123)-123423232'}
                                </Typography>
                            </div>
                            <div className='flex gap-2 justify-center items-center'>
                                <TiMail className='text-[2rem] aspect-square fill-blue-800' />
                                <Typography
                                    as="a"
                                    href="#twitter"
                                    color='blue-gray'
                                    variant="small"
                                    textGradient
                                >abc@gmail.com
                                </Typography>
                            </div>
                        </CardFooter>
                    </Card> */}
                </div>
            </div>


        </div>
    );
}

export default Founders;
