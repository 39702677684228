import { useState, useEffect } from 'react';
import { GlobalVarsContext } from '../context';

export default function GlobalVarsProvider({ children })
{
    const [pxValue, setPxValue] = useState(0);

    const provider = {
        pxValue, setPxValue,
    };

    useEffect(()=>
    {
        console.log('px padding value :', pxValue);
    },[pxValue]);

    return (
        <GlobalVarsContext.Provider value={provider}>
            {children}
        </GlobalVarsContext.Provider>
    );
}
