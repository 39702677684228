import { useState, useEffect } from 'react';
import { RouteContext } from '../context';
import { listItem } from '../../constants/navItems';

export default function RouteProvider({ children })
{
    const [mainRouteId, setMainRouteId] = useState(0);
    const [subRouteId, setSubRouteId] = useState(0);

    const provider = {
        mainRouteId, setMainRouteId,
        subRouteId, setSubRouteId,
    };

    return (
        <RouteContext.Provider value={provider}>
            {children}
        </RouteContext.Provider>
    );
}
