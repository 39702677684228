import { useState, useEffect } from 'react';

import {
    Typography,
    Carousel,
    Card,
    CardHeader,
    CardBody,
} from '@material-tailwind/react';

const imgs = ['assets/office/office1.jpg','assets/office/office2.jpg', 'assets/office/office3.jpg'];

export default function Projects({ className })
{
    const [sliderItems, setSliderItems] = useState(1);
    const items = [];
    const sliderViews = Array.from(Array(sliderItems).keys());

    for (let i = 0; i < 3; i += 1)
    {
        items.push(
            <div
                key={i.toString()}
                className={'flex justify-center h-[30rem] w-full items-center gap-10 p-2'}
                
            >
                {
                    sliderViews.map((item,idx)=>
                    {
                        return (
                            <Card
                                key={idx}
                                className="flex w-full md:w-4/5 h-full overflow-hidden "
                            >
                                <CardHeader
                                    floated={false}
                                    color="transparent"
                                    className='h-1/2'
                                    shadow
                                >
                                    <img
                                        src={imgs[ (i + idx) % imgs.length ]}
                                        alt="ui/ux review check"
                                        className='h-full aspect-auto object-cover object-center'
                                    />
                                </CardHeader>
                                <CardBody className=''>
                                    <Typography
                                        variant="h4"
                                        color="blue-gray"
                                    >
    Welcome to the condo real
                                    </Typography>
                                    <Typography
                                        variant="lead"
                                        color="gray"
                                        className="mt-3 font-normal"
                                    >
    Because it&apos;s about motivating the doers
                                    </Typography>
                                </CardBody>
                            </Card>
                        );
                    })

                }
            </div>,
        );
    }

    useEffect(()=>
    {
        const breakPointSm = 576; // 576px

        // listener
        const resizeListenerHandler = ()=>
        {
            if (window.innerWidth >= breakPointSm)
            { // sm
                setSliderItems(2);
            }
            else
            {
                setSliderItems(1);
            }
        };
        // init value
        resizeListenerHandler();

        window.addEventListener('resize', resizeListenerHandler);
        return ()=>
        {
            window.removeEventListener('resize', resizeListenerHandler);
        };
    },[]);

    return (
        <div className={`h-max min-h-screen w-full flex flex-col items-center justify-center py-20 ${className}`}>
            <Typography
                variant='h1'
                color='blue-gray'
            >Projects
            </Typography>

            <Carousel
                className="grow items-center rounded-xl py-5 md:py-10"
                navigation={({ setActiveIndex, activeIndex, length }) => (
                    <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                        {new Array(length).fill('').map((_, i) => (
                            <span
                                key={i}
                                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                    activeIndex === i ? 'w-8 bg-red-500' : 'w-4 bg-red-500/50'
                                }`}
                                onClick={() => setActiveIndex(i)}
                            />
                        ))}
                    </div>
                )}
                autoplayDelay={3000}
                loop
                autoplay
            >
                {items}
            </Carousel>
        </div>
    );
}


